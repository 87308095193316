.floatingButton{
  border-color: lightgrey;
  border: solid;

  position: absolute;
  bottom: 2rem;
  right: 2rem;

  z-index: 1020;
}

.floatingButton >.show > .dropdown-menu{
  transform: rotateZ(90deg) !important;
  width: 5rem !important;
  min-width: 5rem !important;
}
