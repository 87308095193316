.top{
  display: inline-block;
  width: 100%;
}
.cvItemContainer{
  font-size: 1rem;
  width: 80vw;
}

h5{
  display: inline !important;
}

.cvSeparator{
  background-color: var(--success)!important;
}
