@keyframes rotate0to90 {
  0% {
    transform: rotateY(0deg)
  }
  100% {
    transform: rotateY(90deg)
  }
}

@keyframes rotate0to90Mobile {
  0% {
    transform: rotateZ(90deg) rotateY(0deg);
  }
  100% {
    transform: rotateZ(90deg) rotateY(90deg);
  }
}

@keyframes rotate90to180 {
  0% {
    transform: rotateY(90deg)
  }
  100% {
    transform: rotateY(180deg)
  }
}

@keyframes rotate90to180Mobile {
  0% {
    transform: rotateZ(90deg) rotateY(90deg)
  }
  100% {
    transform: rotateZ(90deg) rotateY(180deg)
  }
}

@keyframes rotate90to0 {
  0% {
    transform: rotateY(90deg)
  }
  100% {
    transform: rotateY(0deg)
  }
}

@keyframes rotate90to0Mobile {
  0% {
    transform: rotateZ(90deg) rotateY(90deg)
  }
  100% {
    transform: rotateZ(90deg) rotateY(0deg)
  }
}


@keyframes rotate180to90 {
  0% {
    transform: rotateY(180deg)
  }
  100% {
    transform: rotateY(90deg)
  }
}

@keyframes rotate180to90Mobile {
  0% {
    transform: rotateZ(90deg) rotateY(180deg)
  }
  100% {
    transform: rotateZ(90deg) rotateY(90deg)
  }
}





@media only screen and (max-width: 600px) {
  .rotate0to90 {
    animation-name: rotate0to90Mobile;
    animation-duration: 0s;
    animation-timing-function: linear;
  }

  .rotate90to180 {
    animation-name: rotate90to180Mobile;
    animation-duration: 0s;
    animation-timing-function: linear;
  }

  .rotate90to0 {
    animation-name: rotate90to0Mobile;
    animation-duration: 0s;
    animation-timing-function: linear;
  }

  .rotate180to90 {
    animation-name: rotate180to90Mobile;
    animation-duration: 0s;
    animation-timing-function: linear;
  }

  .backfront {
    /*transform: rotateY(-180deg);*/
  }

  .callingCardContainer {
    transform: rotateZ(90deg);
  }

  .card {
    background-color: #1A1A1A !important;
    color: white;

    height: 100vw !important;
    width: 100vh !important;

    cursor: pointer;

    font-size: 2em;
  }

  .icon {
    width: 7em;
    height: 7em;

    border-radius: 3.5em;

    margin-bottom: 1em;
  }
}

@media only screen and (min-width: 600px) {
  .rotate0to90 {
    animation-name: rotate0to90;
    animation-duration: 0.5s;
    animation-timing-function: linear;
  }

  .rotate90to180 {
    animation-name: rotate90to180;
    animation-duration: 0.5s;
    animation-timing-function: linear;
  }

  .rotate90to0 {
    animation-name: rotate90to0;
    animation-duration: 0.5s;
    animation-timing-function: linear;
  }

  .rotate180to90 {
    animation-name: rotate180to90;
    animation-duration: 0.5s;
    animation-timing-function: linear;
  }

  .backfront {
    transform: rotateY(-180deg);
  }

  .callingCardContainer {
    margin-top: 25vh;

    margin-right: auto;
    margin-left: auto;
    width: fit-content;
  }

  .card {
    background-color: #1A1A1A !important;
    color: white;

    height: 30rem;
    width: 60rem !important;

    font-size: 2rem;

    cursor: pointer;
  }

  .icon {
    width: 8em;
    height: 8em;

    border-radius: 4em;

    margin-bottom: 1.5em;
  }
}

@keyframes cardHover {
  0% {
    transform: rotateY(0deg)
  }
  100% {
    transform: rotateY(20deg)
  }
}

.card-body {
  margin: 1% 5% !important;
}

.tagContainer {
  font-size: 1.2em;
  width: fit-content;
}

.contactList {
  width: fit-content;
  padding: 0;
  list-style: none;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.socialListItem {
  max-height: 2em !important;
  height: 2em !important;
  margin-bottom: 5px;
}

.contactIcons {
  height: 2rem;
  width: 2rem;
  fill: white;
}

.turnIcon {
  z-index: 1020;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.contactText > a{
  color: white;
}

.contactText >a:hover{
  color: var(--success) !important;
}

.contactText {
  height: fit-content;
  width: fit-content;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  color: white;

  margin-left: 2rem;
}

.card-body > hr{
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}
