

@media only screen and (min-width: 600px) {
  .cvList {
    border-left: solid;
    border-color: var(--success);

    list-style: outside;
    padding: 1.2rem;
    width: fit-content;
    margin-bottom: 3em;
  }

  .cvContainer{
    padding-top: 10vh;
    padding-bottom: 10vh;

    width: fit-content;

    margin-left: auto;
    margin-right: auto;
  }

  .cvListItem {

    max-width: 80vw !important;

    margin-bottom: 2em;
    font-size: 2em !important;
  }
}

@media only screen and (max-width: 600px) {
  .cvList {
    list-style-type: none;
    width: fit-content;
    margin-bottom: 3rem;
    margin-top: 3rem;

    padding-left: 0;
  }

  .cvContainer{
    margin-top: 5vh;
    margin-left: 1vw;
  }

  .cvListItem {

    max-width: 80vw;

    margin-bottom: 2rem;
    font-size: 2em !important;
  }
}


