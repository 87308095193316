.content {
  width: 100% !important;
  padding-bottom: 10vh;
}

.container{
  padding-top: 10vh !important;
}

.footer{
  position: fixed !important;
  height: 8vh !important;
  z-index: 1020;
  width: 100%;
  bottom: 0;
  left: 0;

  font-size: 1rem!important;

  padding: 0.5rem !important;
}

.header{
  position: fixed !important;
  width: 100%;
  top: 0;
  left: 0;

  font-size: 1rem!important;

  padding: 0.5rem !important;
}

.horizontalCenter {
  margin-left: auto ;
  margin-right: auto ;
}

.fitContent{
  width: fit-content;
}

a:hover{
  color: var(--success) !important;
}

html{
  height: 100vh;
}
